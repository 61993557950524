import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CustomFieldModal from "./CustomFieldModal";
import { toast } from "react-hot-toast";
import { AuthContext } from "../AuthContext";

function VisualizedData({ responseData, previewModalContent }) {
  const [editableData, setEditableData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchData } = useContext(AuthContext);

  useEffect(() => {
    if (responseData && Object.keys(responseData).length > 0) {
      setEditableData(responseData);
    } else {
      setEditableData({});
    }
  }, [responseData, previewModalContent]);

  const handleAddCustomField = (field) => {
    if (Array.isArray(editableData?.items)) {
      setEditableData((prevData) => ({
        ...prevData,
        [field.key]: field.value,
      }));
    } else {
      setEditableData((prevData) => {
        const updatedData = { ...prevData };
        const firstSectionKey = Object.keys(prevData)[1];
        if (Array.isArray(updatedData[firstSectionKey])) {
          updatedData[firstSectionKey].push({
            data: [[field.key, field.value]],
          });
        }
        return updatedData;
      });
    }
  };

  const handleDataChange = (key, value) => {
    setEditableData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleNestedDataChange = (
    parentKey,
    sectionIndex,
    entryIndex,
    value
  ) => {
    setEditableData((prevData) => {
      const updatedData = { ...prevData };
      const sections = [...updatedData[parentKey]];
      const section = { ...sections[sectionIndex] };
      section.data = [...section.data];
      section.data[entryIndex] = [section.data[entryIndex][0], value];
      sections[sectionIndex] = section;
      updatedData[parentKey] = sections;
      return updatedData;
    });
  };

  const handleSaveData = async () => {
    setIsSubmitting(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        setIsSubmitting(false);
        return;
      }

      if (!previewModalContent?.id) {
        toast.error("File ID is missing.");
        setIsSubmitting(false);
        return;
      }

      const updatedData = { ...editableData };

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent.id}/update/`,
        {
          information: updatedData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");
        const bulkReviewContent =
          JSON.parse(localStorage.getItem("bulkReviewContent")) || [];
        const updatedBulkReviewContent = bulkReviewContent.map((file) => {
          if (file.id === previewModalContent.id) {
            return {
              ...file,
              information: response.data.information,
            };
          }
          return file;
        });
        localStorage.setItem(
          "bulkReviewContent",
          JSON.stringify(updatedBulkReviewContent)
        );
        fetchData();
        setEditableData(updatedData);
      } else {
        toast.error("Failed to update data.");
      }
    } catch (error) {
      toast.error("Error updating data. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Sub total",
      selector: (row) => row.subtotal,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unit_price,
      sortable: true,
    },
  ];

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Render fields for the first format
  const renderFirstFormatFields = () => (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      {Object.entries(editableData).map(
        ([key, value]) =>
          key !== "items" && (
            <div className="mb-2" key={key}>
              <label className="block text-slate-900 mb-1">
                {formatKey(key)}
              </label>
              <input
                type="text"
                className="w-full border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                value={value || ""}
                onChange={(e) => handleDataChange(key, e.target.value)}
              />
            </div>
          )
      )}
    </div>
  );

  // Render fields for the second format
  // const renderSecondFormatFields = () => {
  //   // Collect all entries from section.data into a single list
  //   const allEntries = [];

  //   Object.entries(editableData).forEach(([key, sections]) => {
  //     if (Array.isArray(sections)) {
  //       sections.forEach((section) => {
  //         if (Array.isArray(section.data)) {
  //           allEntries.push(
  //             ...section.data.map((entry, entryIndex) => ({
  //               key,
  //               sectionIndex: sections.indexOf(section),
  //               entryIndex,
  //               entry,
  //             }))
  //           );
  //         }
  //       });
  //     }
  //   });

  //   return (
  //     <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
  //       {allEntries.map(({ key, sectionIndex, entryIndex, entry }) => (
  //         <div className="mb-2" key={`${key}-${sectionIndex}-${entryIndex}`}>
  //           <label className="block text-slate-900 mb-1">{entry[0]}</label>
  //           <input
  //             type="text"
  //             className="w-full p-2 bg-gray-200 text-gray-800 rounded"
  //             value={entry[1] || ""}
  //             onChange={(e) =>
  //               handleNestedDataChange(
  //                 key,
  //                 sectionIndex,
  //                 entryIndex,
  //                 e.target.value
  //               )
  //             }
  //           />
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const renderSecondFormatFields = () => {
    // Collect all entries from section.data into a single list
    const allEntries = [];

    Object.entries(editableData).forEach(([key, sections]) => {
      if (Array.isArray(sections)) {
        sections.forEach((section, sectionIndex) => {
          if (Array.isArray(section.data)) {
            allEntries.push(
              ...section.data.map((entry, entryIndex) => ({
                key,
                sectionIndex,
                entryIndex,
                entry,
              }))
            );
          }
        });
      }
    });

    return (
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {allEntries.map(({ key, sectionIndex, entryIndex, entry }) => (
          <div className="mb-2" key={`${key}-${sectionIndex}-${entryIndex}`}>
            <label className="block text-slate-900 mb-1">
              {formatKey(entry[0])}
            </label>
            <input
              type="text"
              className="w-full border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
              value={entry[1] || ""}
              onChange={(e) =>
                handleNestedDataChange(
                  key,
                  sectionIndex,
                  entryIndex,
                  e.target.value
                )
              }
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <form
      className="rounded p-4 bg-[#FFFFFF] max-h-[30rem] overflow-auto"
      style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px" }}
    >
      {editableData &&
        typeof editableData === "object" &&
        Object.keys(editableData).length > 0 && (
          <>
            {Array.isArray(editableData?.items)
              ? renderFirstFormatFields()
              : renderSecondFormatFields()}

            <div className="mt-4 flex justify-between">
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              >
                Add Custom Field
              </button>
              <button
                type="button"
                onClick={handleSaveData}
                className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </button>
            </div>

            {/* Custom Field Modal */}
            <CustomFieldModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSave={handleAddCustomField}
            />
          </>
        )}

      {/* Render Data Table */}
      {Array.isArray(editableData?.items) && editableData.items.length > 0 && (
        <div className="mt-4">
          <DataTable
            columns={columns}
            data={editableData.items}
            pagination
            className="text-gray-800"
          />
        </div>
      )}
    </form>
  );
}

export default VisualizedData;
