import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import { BeatLoader } from "react-spinners";
import { formatTimestampDateComponent } from "../components/Helper";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionDetails = () => {
      try {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData) {
          let paymentDetails = {
            "Available Credits": userData?.credits_available,
          };
          if (userData?.is_trial_period) {
            paymentDetails["Trial Expired At"] = userData.trial_expired_at
              ? formatTimestampDateComponent(
                  "trial_expired_at",
                  userData,
                  "MMM D, YY"
                )
              : "";
          } else {
            paymentDetails["Plan Name"] = userData?.razorpay_plan_name;
            paymentDetails["Plans Starts At"] =
              userData.razorpay_subscription_start_at
                ? formatTimestampDateComponent(
                    "razorpay_subscription_start_at",
                    userData,
                    "MMM D, YY"
                  )
                : "";
            paymentDetails["Plan Ends At"] =
              userData.razorpay_subscription_end_at
                ? formatTimestampDateComponent(
                    "razorpay_subscription_end_at",
                    userData,
                    "MMM D, YY"
                  )
                : "";
          }
          setSubscriptionDetails(paymentDetails);
        } else {
          throw new Error("No user data found in local storage.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, []);

  const handleCancelSubscription = () => {
    alert("Subscription cancelled!");
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleString();
  // };

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <BeatLoader />
      </div>
    );
  }

  // if (error) {
  //   return (
  //     <div className="h-screen flex justify-center items-center">
  //       Error: {error}
  //     </div>
  //   );
  // }

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col sm:flex-row sm:justify-between items-center relative p-4">
            <h1 className="text-2xl sm:text-3xl font-bold text-center sm:absolute sm:left-1/2 sm:transform sm:-translate-x-1/2 mb-4 sm:mb-0">
              Subscription Details
            </h1>

            <button
              className="p-2 font-bold sm:ml-auto cursor-pointer     bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              onClick={() => navigate("/plans")}
            >
              View All Plans
            </button>
          </div>
        </motion.div>

        <motion.div
          className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="space-y-4">
            {/* {console.log("subscriptionDetails", subscriptionDetails)} */}
            {subscriptionDetails &&
              Object.entries(subscriptionDetails).map(([key, value]) => (
                <>
                  {/* {console.log("key, value", key, value)} */}
                  <div key={key} className="flex justify-between">
                    <span className="font-bold text-gray-700">
                      {key
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                      :
                    </span>
                    <span
                      className={`text-gray-600 break-words ${
                        value.length > 50 ? "truncate max-w-xs" : ""
                      }`}
                      title={
                        typeof value === "string" && value.length > 50
                          ? value
                          : ""
                      }
                    >
                      {typeof value === "string" && value.length > 50
                        ? value.slice(0, 50) + "..."
                        : value}
                    </span>
                  </div>
                </>
              ))}
          </div>

          <motion.div
            className="text-center mt-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <button
              onClick={handleCancelSubscription}
              className="bg-white text-red-500 font-bold py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
            >
              Cancel Subscription
            </button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Subscription;
