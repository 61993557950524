import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import AddEmployeeModal from "../components/AddEmployeeModal";
import axios from "axios";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";

const ManageUsers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const allUsers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/employees/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setUsers(response?.data?.employees);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    allUsers();
  }, []);

  const toggleUserStatus = async (userId, currentStatus) => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        toast.error("Access token not found");
        return;
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/user/toggle-status/${userId}/`,
        { is_active: !currentStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.success) {
        toast.success(response.data.message || "User status updated!");
        allUsers();
      }
    } catch (error) {
      toast.error("Failed to update status. Please try again.");
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <div className="flex justify-end mb-4">
          <button
            onClick={openModal}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
          >
            Add Employee
          </button>
        </div>

        {/* AddEmployeeModal */}
        <AddEmployeeModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          allUsers={allUsers}
        />

        {/* Loader */}
        {loading ? (
          <div className="flex justify-center items-center p-4">
            <BeatLoader />
          </div>
        ) : (
          <div className="bg-white shadow-md rounded my-6 w-full max-w-[90vw] lg:max-w-[80vw] mx-auto overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Id
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Name
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Last Login
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Uploads
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6 text-left">{user.id}</td>
                    <td className="py-3 px-6 text-left">
                      {user.first_name + " " + user?.last_name}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.last_login === null ? "N/A" : user?.last_login}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.total_uploads}
                    </td>
                    <td className="py-3 px-6 text-left">
                      <button
                        onClick={() =>
                          toggleUserStatus(user.id, user.is_active)
                        }
                        className={`relative inline-flex items-center px-3 py-1 rounded-full w-20 h-8 transition-colors duration-300 ${
                          user.is_active ? "bg-green-500" : "bg-red-500"
                        }`}
                      >
                        <span
                          className={`absolute inline-block w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                            user.is_active ? "translate-x-9" : "translate-x-1"
                          }`}
                        />
                        <span className="text-white text-sm font-medium ml-2">
                          {user.is_active ? "Active" : "Inactive"}
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageUsers;
