import React, { useCallback, useContext, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import Zoom from "react-medium-image-zoom";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-medium-image-zoom/dist/styles.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { MdOutlineZoomOutMap } from "react-icons/md";
import toast from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import Spinner from "./Spinner";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const FileViewer = ({
  fileLink,
  fileType,
  setResponseData,
  setIsLoading,
  previewModalContent,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isExtracting, setIsExtracting] = useState(false);
  const { fetchData, userData } = useContext(AuthContext);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const handleExtract = async () => {
    try {
      setIsExtracting(true);
      setIsLoading(true);

      let blob;
      if (fileLink.startsWith("blob:")) {
        blob = await fetch(fileLink).then((res) => res.blob());
      } else {
        const response = await fetch(fileLink);
        if (!response.ok) throw new Error("Failed to fetch file.");
        blob = await response.blob();
      }

      const mimeType =
        blob.type ||
        (fileLink.endsWith(".pdf") ? "application/pdf" : "image/jpeg");
      const fileName = mimeType.includes("pdf")
        ? "extracted_file.pdf"
        : "extracted_file.jpg";

      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent.id}/update/`,
        {
          total_pages: numPages ?? 1,
          extract: true,
          status: "EXTRACTED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.information", response.data.information);
      setResponseData(response.data.information);

      const bulkReviewContent =
        JSON.parse(localStorage.getItem("bulkReviewContent")) || [];
      const updatedBulkReviewContent = bulkReviewContent.map((file) => {
        if (file.id === previewModalContent.id) {
          return {
            ...file,
            information: response.data.information,
          };
        }
        return file;
      });
      localStorage.setItem(
        "bulkReviewContent",
        JSON.stringify(updatedBulkReviewContent)
      );

      fetchData();
      userData();
    } catch (error) {
      console.error("Failed to extract data:", error);
      toast.error("Data extraction failed. Please try again.");
    } finally {
      setIsLoading(false);
      setIsExtracting(false);
    }

    console.log("Extraction completed");
  };

  return (
    <div className="p-0 flex justify-center items-center">
      {["jpg", "jpeg", "png"].includes(fileType) && (
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <button
              className="py-1 px-2 w-8 h-8 bg-sky-400 text-white rounded-md hover:bg-sky-500 border-sky-500 border transition flex justify-center items-center"
              type="button"
              onClick={() => {
                window.open(fileLink, "_blank");
              }}
            >
              <MdOutlineZoomOutMap />
            </button>
            {!fileLink.startsWith("blob:") && (
              <div>
                <button
                  className="bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  type="button"
                  onClick={handleExtract}
                  disabled={isExtracting}
                >
                  {isExtracting ? "Extracting..." : "Extract"}{" "}
                </button>
              </div>
            )}
          </div>
          <Zoom>
            <QuickPinchZoom onUpdate={onUpdate}>
              <img
                ref={imgRef}
                src={fileLink}
                alt="Example"
                className="max-w-full h-auto rounded-lg shadow-md"
              />
            </QuickPinchZoom>
          </Zoom>
        </div>
      )}

      {fileType === "pdf" && (
        <div className="">
          <div className="mb-2 flex items-center px-2 justify-between">
            <div className="flex space-x-2">
              <button
                className="py-1 px-2 w-8 h-8 bg-sky-400 text-white rounded-md hover:bg-sky-500 border-slate-500 border transition flex justify-center items-center"
                type="button"
                onClick={() => {
                  window.open(fileLink, "_blank");
                }}
              >
                <MdOutlineZoomOutMap />
              </button>
            </div>
            {!fileLink.startsWith("blob:") && (
              <div>
                <button
                  className="bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  type="button"
                  onClick={handleExtract}
                  disabled={isExtracting}
                >
                  {isExtracting ? "Extracting..." : "Extract"}{" "}
                </button>
              </div>
            )}
          </div>

          <div className="w-full h-full p-4 py-1 pdf-container">
            <div
              className="border rounded-lg overflow-auto w-full bg-white"
              // style={{ maxWidth: "26vw", height: "68vh" }}
              style={{ maxHeight: "68vh" }}
            >
              <Document
                file={fileLink}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) =>
                  console.error("Error loading PDF:", error)
                }
                className="w-full h-full overflow-auto"
                // loading={<Spinner />}
                // options={{ renderAnnotations: false, renderTextLayer: false }}
              >
                <Page
                  pageNumber={pageNumber}
                  className="rounded-lg shadow-md"
                  width={window.innerWidth * 0.25}
                  height={window.innerHeight * 0.2}
                />
              </Document>
            </div>
          </div>

          <div className="flex justify-between items-center mt-2 pb-8">
            <button
              disabled={pageNumber <= 1}
              onClick={() => changePage(-1)}
              className="rounded-md bg-slate-200 text-black hover:bg-slate-400 transition disabled:opacity-50 p-1"
            >
              <FaAnglesLeft size={15} color="black" />
            </button>
            <p className="text-center">
              <span className="bg-slate-200 p-1 rounded">
                Page {pageNumber} of {numPages}
              </span>
            </p>
            <button
              disabled={pageNumber >= numPages}
              onClick={() => changePage(1)}
              className="rounded-md bg-slate-200 text-black hover:bg-slate-400 transition disabled:opacity-50 p-1"
            >
              <FaAnglesRight size={15} color="black" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileViewer;
