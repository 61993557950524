import React, { useState } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import { unparse } from "papaparse";
import * as XLSX from "xlsx";

const BulkDownloadModal = ({
  isBulkDownloadModalOpen,
  closeBulkDownloadModal,
  downloadBulkModalContent,
}) => {
  const [loadingState, setLoadingState] = useState({});

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      minHeight: "70vh",
      maxHeight: "90vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#F6F5F5",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const exportJSONToCSV = (data, filename = "data.csv") => {
    const parsedData = data?.information;
    if (!parsedData || !Array.isArray(parsedData)) {
      console.error("Failed to parse data for CSV export");
      return;
    }

    const csv = unparse(parsedData);

    const bom = "\uFEFF";
    const csvWithBom = bom + csv;

    const blob = new Blob([csvWithBom], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportJSONToXLSX = (data, filename = "data.xlsx") => {
    const parsedData = data?.information;

    const processedData = Object.entries(parsedData).reduce(
      (acc, [key, value]) => {
        if (value === null) {
          acc[key] = "";
        } else if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          acc[key] = JSON.stringify(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    // Handle the items array separately
    const items = parsedData.items || [];
    const worksheet = XLSX.utils.json_to_sheet(items);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Items");

    // Add a sheet for other data
    const otherDataWorksheet = XLSX.utils.json_to_sheet([processedData]);
    XLSX.utils.book_append_sheet(workbook, otherDataWorksheet, "Details");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownload = (type, file, index) => {
    setLoadingState({ [index]: type });

    setTimeout(() => {
      const filename = `${file.file.split("/").pop()}.${type}`;
      if (type === "csv") {
        exportJSONToCSV(file, filename);
      } else if (type === "xlsx") {
        exportJSONToXLSX(file, filename);
      }
      setLoadingState({}); // Reset the loading state after download
    }, 3000);
  };

  return (
    <div>
      <Modal
        isOpen={isBulkDownloadModalOpen}
        onRequestClose={closeBulkDownloadModal}
        contentLabel="Bulk Download Modal"
        style={customStyles}
      >
        <h2>Bulk Download Items</h2>
        {downloadBulkModalContent && downloadBulkModalContent.length > 0 ? (
          <div className="p-4">
            {downloadBulkModalContent.map((file, index) => (
              <div key={index} className="mb-4 p-2 border rounded shadow-sm">
                <div className="flex flex-wrap gap-5 justify-between">
                  <p className="text-sm font-semibold">
                    Name:{" "}
                    <span className="font-normal">
                      {file.file.split("/").pop()}
                    </span>
                  </p>
                  <p className="text-sm font-semibold">
                    Type: <span className="font-normal">{file.file_type}</span>
                  </p>
                  <p className="text-sm font-semibold">
                    Owner:{" "}
                    <span className="font-normal">{file.uploaded_by}</span>
                  </p>
                </div>
                <div className="flex justify-end space-x-4 mt-2">
                  <button
                    onClick={() => handleDownload("csv", file, index)}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
                    disabled={loadingState[index] !== undefined}
                  >
                    {loadingState[index] === "csv"
                      ? "Downloading..."
                      : "Download CSV"}
                  </button>
                  <button
                    onClick={() => handleDownload("xlsx", file, index)}
                    className="px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600"
                    disabled={loadingState[index] !== undefined}
                  >
                    {loadingState[index] === "xlsx"
                      ? "Downloading..."
                      : "Download XLSX"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No files available for bulk download.</p>
        )}
        <button
          onClick={closeBulkDownloadModal}
          className="absolute top-4 right-4"
        >
          <span className="rounded-full text-gray-500 font-bold">
            <RxCross2 size={20} />
          </span>
        </button>
      </Modal>
    </div>
  );
};

export default BulkDownloadModal;
