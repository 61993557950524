import React, { useContext, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import toast from "react-hot-toast";
import { AuthContext } from "../AuthContext";

const DeleteFolderModal = ({
  isDeleteFolderModalOpen,
  closeDeleteFolderModal,
  folderId,
  setFolderId,
}) => {
  const { fetchData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        console.error("Access token not found in localStorage.");
        setIsLoading(false);
        return;
      }

      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/folders/${folderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 204) {
        // toast.success("Folder deleted successfully!");
        setFolderId(null);
        fetchData();
        closeDeleteFolderModal();
      } else {
        toast.error("Unexpected response from server.");
      }
    } catch (error) {
      //   console.error("Failed to delete the folder:", error);
      toast.error("Error deleting folder. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isDeleteFolderModalOpen}
        onRequestClose={closeDeleteFolderModal}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
          <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
          <p className="text-gray-700 mb-6">
            Are you sure you want to delete this folder?
          </p>
          <div className="flex justify-end gap-4">
            <button
              onClick={closeDeleteFolderModal}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className={`px-4 py-2 rounded-md text-white ${
                isLoading ? "bg-red-400" : "bg-red-500 hover:bg-red-600"
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteFolderModal;
