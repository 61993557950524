import React from "react";

const Spinner = ({ bdColor = "border-slate-800" }) => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={`w-16 h-16 border-4 ${bdColor} border-dashed rounded-full animate-spin`}
      ></div>
    </div>
  );
};

export default Spinner;
