import React, { useState } from "react";
import Modal from "react-modal";
import { unparse } from "papaparse";
import * as XLSX from "xlsx";
import { RxCross2 } from "react-icons/rx";

const DownloadModal = ({
  isDownloadModalOpen,
  closeDownloadModal,
  downloadModalContent,
}) => {
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingXLSX, setIsLoadingXLSX] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      minHeight: "70vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#F6F5F5",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const exportJSONToCSV = (data, filename = "data.csv") => {
    const parsedData = data?.information;
    if (!parsedData || !Array.isArray(parsedData)) {
      console.error("Failed to parse data for CSV export");
      return;
    }

    const csv = unparse(parsedData);

    const bom = "\uFEFF";
    const csvWithBom = bom + csv;

    const blob = new Blob([csvWithBom], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportJSONToXLSX = (data, filename = "data.xlsx") => {
    const parsedData = data?.information;

    const processedData = Object.entries(parsedData).reduce(
      (acc, [key, value]) => {
        if (value === null) {
          acc[key] = "";
        } else if (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          acc[key] = JSON.stringify(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    // Handle the items array separately
    const items = parsedData.items || [];
    const worksheet = XLSX.utils.json_to_sheet(items);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Items");

    // Add a sheet for other data
    const otherDataWorksheet = XLSX.utils.json_to_sheet([processedData]);
    XLSX.utils.book_append_sheet(workbook, otherDataWorksheet, "Details");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownload = (type) => {
    if (type === "csv") {
      setIsLoadingCSV(true);
    } else if (type === "xlsx") {
      setIsLoadingXLSX(true);
    }

    setTimeout(() => {
      if (type === "csv") {
        exportJSONToCSV(
          downloadModalContent,
          `${downloadModalContent?.file.split("/").pop() || "data"}.csv`
        );
        setIsLoadingCSV(false);
      } else if (type === "xlsx") {
        exportJSONToXLSX(
          downloadModalContent,
          `${downloadModalContent?.file.split("/").pop() || "data"}.xlsx`
        );
        setIsLoadingXLSX(false);
      }
    }, 3000);
  };

  return (
    <div>
      <Modal
        isOpen={isDownloadModalOpen}
        onRequestClose={closeDownloadModal}
        contentLabel="Download Modal"
        style={customStyles}
      >
        <h2>Download Item</h2>
        {downloadModalContent && (
          <div className="p-4">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-wrap gap-5 justify-evenly">
                <p className="text-sm font-semibold">
                  Name:{" "}
                  <span className="font-normal">
                    {downloadModalContent?.file.split("/").pop()}
                  </span>
                </p>
                <p className="text-sm font-semibold">
                  Type:{" "}
                  <span className="font-normal">
                    {downloadModalContent?.file_type}
                  </span>
                </p>
                <p className="text-sm font-semibold">
                  Owner:{" "}
                  <span className="font-normal">
                    {downloadModalContent?.owner}
                  </span>
                </p>
              </div>
              <div className="absolute bottom-20 left-0 min-w-full">
                <div className="flex flex-wrap justify-center items-center gap-5">
                  <button
                    onClick={() => handleDownload("csv")}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
                    disabled={isLoadingCSV || isLoadingXLSX}
                  >
                    {isLoadingCSV ? "Downloading..." : "Download CSV"}
                  </button>
                  <button
                    onClick={() => handleDownload("xlsx")}
                    className="px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600"
                    disabled={isLoadingCSV || isLoadingXLSX}
                  >
                    {isLoadingXLSX ? "Downloading..." : "Download XLSX"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <button onClick={closeDownloadModal} className="absolute top-4 right-4">
          <span className="rounded-full text-gray-500 font-bold">
            <RxCross2 size={20} />
          </span>
        </button>
      </Modal>
    </div>
  );
};

export default DownloadModal;
