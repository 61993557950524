import { Route, Routes } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import CompanyInfo from "./pages/CompanyInfo";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/PageNotFound";
import { Toaster } from "react-hot-toast";
import RegisterPage from "./pages/RegisterPage";
import MyAccount from "./pages/MyAccount";
import CompanySettings from "./pages/CompanySettings";
import ManageUsers from "./pages/ManageUsers";
import Subscription from "./pages/Subscription";
import VerifyRegisterEmail from "./pages/VerifyRegisterEmail";
import VerifyLoginOTP from "./pages/VerifyLoginOTP";
import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import Plans from "./pages/Plans";

import { Navigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import Activities from "./pages/Activities";

function ProtectedRoute({ children }) {
  const { isLoggedIn, isSubscribed, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
        <ScaleLoader color="#4A90E2" />
      </div>
    );
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (!isSubscribed) {
    return <Navigate to="/plans" />;
  }

  return children;
}

function App() {
  const { isLoggedIn, isSubscribed } = useContext(AuthContext);

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/verify-register-email/:email"
          element={<VerifyRegisterEmail />}
        />
        <Route path="/verify-login-otp/:email" element={<VerifyLoginOTP />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/" element={<Dashboard />} />

        <Route
          path="/company-info"
          element={
            <ProtectedRoute>
              <CompanyInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/documents/:folderName"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <MyAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company-settings"
          element={
            <ProtectedRoute>
              <CompanySettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-users"
          element={
            <ProtectedRoute>
              <ManageUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-subscription"
          element={
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/activities"
          element={
            <ProtectedRoute>
              <Activities />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
