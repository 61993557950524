import React, { useState, useEffect, useContext } from "react";
import { FaEye, FaFilePdf } from "react-icons/fa";
import { FaFile } from "react-icons/fa";
import { RxDownload } from "react-icons/rx";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiFolder } from "react-icons/fi";
import DownloadModal from "./DownloadModal";
import DeleteModal from "./DeleteModal";
import PreviewModal from "./PreviewModal";
import { AuthContext } from "../AuthContext";
import { IoMdAdd } from "react-icons/io";
import NewFolderModal from "./NewFolderModal";
import DeleteFolderModal from "./DeleteFolderModal";
import { BeatLoader } from "react-spinners";

const DataTable = ({
  selectedAllDocuments,
  setBulkReviewContent,
  setBulkDownloadContent,
}) => {
  const [currentData, setCurrentData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([
    { name: "All Documents", id: null, data: [] },
  ]);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadModalContent, setDownloadModalContent] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalContent, setPreviewModalContent] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [parentFolderId, setParentFolderId] = useState(null);

  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [folderId, setFolderId] = useState(null);

  const { fetchData, tableData, tableLoader } = useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (tableData?.length > 0) {
      const rootFolders = tableData.filter((item) => item.parent === null);
      const rootFiles = tableData.filter((item) => item.folder === null);

      setCurrentData([...rootFolders, ...rootFiles]);
      setBreadcrumb([
        {
          name: "All Documents",
          id: null,
          data: [...rootFolders, ...rootFiles],
        },
      ]);
    } else {
      setCurrentData([]);
    }
  }, [tableData]);

  // console.log(tableData);

  useEffect(() => {
    if (selectedAllDocuments) {
      setSelectedRows(currentData.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  }, [selectedAllDocuments, currentData]);

  const handleRowSelection = (identifier) => {
    let updatedSelectedRows;

    if (selectedRows.includes(identifier)) {
      updatedSelectedRows = selectedRows.filter((item) => item !== identifier);

      const selectedFolder = currentData.find(
        (item) => item.id === identifier && item.subfolders !== undefined
      );
      if (selectedFolder) {
        const folderFileIds = selectedFolder.files.map((file) => file.id);
        updatedSelectedRows = updatedSelectedRows.filter(
          (id) => !folderFileIds.includes(id)
        );
      }
    } else {
      updatedSelectedRows = [...selectedRows, identifier];

      const selectedFolder = currentData.find(
        (item) => item.id === identifier && item.subfolders !== undefined
      );
      if (selectedFolder) {
        const folderFileIds = selectedFolder.files.map((file) => file.id);
        updatedSelectedRows = [...updatedSelectedRows, ...folderFileIds];
      }
    }

    setSelectedRows(updatedSelectedRows);
  };

  const handleFolderClick = (folder) => {
    const combinedData = [...folder.subfolders, ...folder.files];
    setBreadcrumb((prevBreadcrumb) => [
      ...prevBreadcrumb,
      { name: folder.name, id: folder.id, data: combinedData },
    ]);
    setCurrentData(combinedData);
  };

  const handleBreadcrumbClick = (index) => {
    const newBreadcrumb = breadcrumb.slice(0, index + 1);
    const folder = newBreadcrumb[index];
    setCurrentData(folder.data);
    setBreadcrumb(newBreadcrumb);
  };

  const handleDownloadClick = (item) => {
    setDownloadModalContent(item);
    setIsDownloadModalOpen(true);
  };

  const handlePreviewClick = (item) => {
    const fileExtension = item.file?.split(".").pop().toLowerCase();
    const fileType = ["pdf", "jpg", "jpeg", "png"].includes(fileExtension)
      ? fileExtension
      : "unknown";

    setPreviewModalContent({
      fileLink: process.env.REACT_APP_BACKEND_URL + item.file,
      format: fileType,
      name: item.file?.split("/").pop(),
      id: item.id,
      information: item.information,
      status: item.status,
    });

    setIsPreviewModalOpen(true);
  };

  const handleDeleteClick = (item) => {
    setFileId(item.id);
    setIsDeleteModalOpen(true);
  };

  const handleFolderDeleteClick = (folder) => {
    setFolderId(folder.id);
    setIsDeleteFolderModalOpen(true);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
    setDownloadModalContent(null);
  };

  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
    setPreviewModalContent(null);
  };

  const userData = JSON.parse(localStorage.getItem("auth"))?.user;

  const getUsernameById = (userId) => {
    return userData
      ? `${userData.first_name} ${userData.last_name}`
      : "Unknown";
  };

  useEffect(() => {
    const selectedFiles = [];

    selectedRows.forEach((identifier) => {
      const item = currentData.find((item) => item.id === identifier);

      if (item) {
        if (item.subfolders !== undefined) {
          selectedFiles.push(...item.files);
        } else {
          selectedFiles.push(item);
        }
      }
    });

    setBulkReviewContent(selectedFiles);
    setBulkDownloadContent(selectedFiles);
  }, [selectedRows, currentData, setBulkReviewContent, setBulkDownloadContent]);

  const handleAddNewFolder = () => {
    const lastBreadcrumb = breadcrumb[breadcrumb.length - 1];
    const parentId = lastBreadcrumb.id;

    setParentFolderId(parentId);
    setIsNewFolderModalOpen(true);
  };

  // console.log("currentData", currentData);

  return (
    <div className="overflow-x-auto rounded-md shadow-md">
      <div className="flex items-center space-x-2 whitespace-nowrap mr-10 mt-5 justify-end">
        <span className="text-black font-medium">New Folder</span>
        <button
          className="bg-white text-sky-500 px-2 py-2 border border-sky-400 rounded-full focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
          onClick={handleAddNewFolder}
        >
          <IoMdAdd />
        </button>
      </div>
      <div className="p-4">
        {breadcrumb.map((crumb, index) => (
          <span key={index}>
            <button
              onClick={() => handleBreadcrumbClick(index)}
              className="text-blue-600 hover:underline"
            >
              {crumb.name}
            </button>
            {index < breadcrumb.length - 1 && " > "}
          </span>
        ))}
      </div>

      {tableLoader ? (
        <div className="flex justify-center items-center p-4">
          <BeatLoader />
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Owner
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentData.length > 0 ? (
              currentData.map((item) => (
                <tr
                  key={item.id || item.name}
                  className={`${
                    selectedRows.includes(item.id) ? "bg-blue-50" : ""
                  }`}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(item.id)}
                      onChange={() => handleRowSelection(item.id)}
                    />
                  </td>
                  <td
                    className="px-6 py-4 whitespace-nowrap cursor-pointer"
                    onClick={() =>
                      item.subfolders
                        ? handleFolderClick(item)
                        : handlePreviewClick(item)
                    }
                  >
                    <span className="flex items-center">
                      {item.subfolders ? (
                        <FiFolder className="mr-2" />
                      ) : (
                        <FaFile className="mr-2" />
                      )}
                      {item.name || item.file.split("/").pop()}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {getUsernameById(item.uploaded_by)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.subfolders ? "Folder" : item.file_type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.subfolders ? "-" : item.status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-2">
                      {!item.subfolders && (
                        <>
                          <button
                            onClick={() => handlePreviewClick(item)}
                            className="bg-white text-sky-500 px-2 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                          >
                            <FaEye />
                          </button>
                          <button
                            onClick={() => handleDownloadClick(item)}
                            className="bg-white text-green-500 px-2 py-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-400 hover:text-white transition duration-300 ease-in-out"
                            disabled={item?.status === "NOT_EXTRACTED"}
                          >
                            <RxDownload />
                          </button>
                        </>
                      )}
                      <button
                        onClick={() =>
                          item.subfolders
                            ? handleFolderDeleteClick(item)
                            : handleDeleteClick(item)
                        }
                        className="bg-white text-red-500 px-2 py-2 border border-red-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-400 hover:text-white transition duration-300 ease-in-out"
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="text-center p-4 text-gray-500 whitespace-nowrap"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <NewFolderModal
        isNewFolderModalOpen={isNewFolderModalOpen}
        closeNewFolderModal={() => setIsNewFolderModalOpen(false)}
        parentFolderId={parentFolderId}
      />

      <DownloadModal
        isDownloadModalOpen={isDownloadModalOpen}
        closeDownloadModal={closeDownloadModal}
        downloadModalContent={downloadModalContent}
      />

      <PreviewModal
        isPreviewModalOpen={isPreviewModalOpen}
        closePreviewModal={closePreviewModal}
        previewModalContent={previewModalContent}
      />

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        closeDeleteModal={() => setIsDeleteModalOpen(false)}
        fileId={fileId}
        setFileId={setFileId}
      />

      <DeleteFolderModal
        isDeleteFolderModalOpen={isDeleteFolderModalOpen}
        closeDeleteFolderModal={() => setIsDeleteFolderModalOpen(false)}
        folderId={folderId}
        setFolderId={setFolderId}
      />
    </div>
  );
};

export default DataTable;
