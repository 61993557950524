import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppWrapper = (
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);

// Conditionally render React.StrictMode in development only
// if (process.env.NODE_ENV === "development") {
//   root.render(<React.StrictMode>{AppWrapper}</React.StrictMode>);
// } else {
//   root.render(AppWrapper);
// }
root.render(AppWrapper);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
