import React, { useContext, useState } from "react";
import Header from "../components/Header";
import Dropzone from "../components/Dropzone";
import DataTable from "../components/Table";
import NewFolderModal from "../components/NewFolderModal";
import BulkPreviewModal from "../components/BulkPreviewModal";
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import BulkDownloadModal from "../components/BulkDownloadModal";

const Dashboard = () => {
  const [selectedAllDocuments, setSelectedAllDocuments] = useState(false);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [bulkReviewContent, setBulkReviewContent] = useState([]);
  const [isBulkPreviewModalOpen, setIsBulkPreviewModalOpen] = useState(false);
  const [bulkDownloadContent, setBulkDownloadContent] = useState([]);
  const [isBulkDownloadModalOpen, setIsBulkDownloadModalOpen] = useState(false);

  const {
    isLoggedIn,
    searchKeyword,
    setSearchKeyword,
    setStatusFilter,
    setFileTypeFilter,
    setTypeFilter,
    documentType,
    setDocumentType,
    fileTypeFilter,
    statusFilter,
    typeFilter,
  } = useContext(AuthContext);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleBulkReview = () => {
    if (bulkReviewContent.length === 0) {
      toast("Please choose the files", {
        duration: 2000,
      });
    } else {
      // Store bulk review content in localStorage before opening modal
      localStorage.setItem(
        "bulkReviewContent",
        JSON.stringify(bulkReviewContent)
      );
      setIsBulkPreviewModalOpen(true);
    }
  };

  const handleBulkDownload = () => {
    if (bulkDownloadContent.length === 0) {
      toast("Please choose the files", {
        duration: 2000,
      });
    } else if (
      bulkDownloadContent.some((file) => file.status !== "EXTRACTED")
    ) {
      toast("Please choose only extracted files to download", {
        duration: 2000,
      });
    } else {
      setIsBulkDownloadModalOpen(true);
    }
  };

  const handleFilterClick = (type) => {
    setDocumentType(type);
  };

  const closeBulkPreviewModal = () => {
    // Remove bulkReviewContent from localStorage when modal closes
    localStorage.removeItem("bulkReviewContent");
    setIsBulkPreviewModalOpen(false);
  };

  const handleTypeFilter = (selectedType) => {
    setTypeFilter(selectedType);
  };

  const handleStatusFilter = (selectedStatus) => {
    setStatusFilter(selectedStatus);
  };
  const handleFileTypeFilter = (selectedFileType) => {
    setFileTypeFilter(selectedFileType);
  };

  const handleClearFilter = () => {
    setSearchKeyword("");
    setTypeFilter("All");
    setStatusFilter("All");
    setFileTypeFilter("All");
    setDocumentType("All");
  };

  return (
    <div>
      <Header />
      <div className="flex flex-wrap items-center space-x-4 justify-center mb-4 mt-4">
        <div className="flex flex-wrap justify-start items-center md:min-w-[75vw] max-w-[90vw]">
          <h1 className="text-xl font-bold mr-6 px-2">Documents</h1>
          <div className="flex gap-1 overflow-x-auto no-scrollbar md:space-x-4 px-2 p-1">
            <button
              className={`px-4 py-0.5 border border-gray-300 rounded-full bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-sky-600 whitespace-nowrap ${
                documentType === "All" ? "border-2 border-sky-500" : ""
              }`}
              onClick={() => handleFilterClick("All")}
              value={documentType}
            >
              All Documents
            </button>
            <button
              className={`px-4 py-0.5 border border-gray-300 rounded-full bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-sky-600 whitespace-nowrap ${
                documentType === "INVOICE" ? "border-2 border-sky-500" : ""
              }`}
              onClick={() => handleFilterClick("INVOICE")}
              value={documentType}
            >
              Invoices
            </button>
            <button
              className={`px-4 py-0.5 border border-gray-300 rounded-full bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-sky-600 whitespace-nowrap ${
                documentType === "RECEIPT" ? "border-2 border-sky-500" : ""
              }`}
              onClick={() => handleFilterClick("RECEIPT")}
              value={documentType}
            >
              Receipts
            </button>
            {/* <button className="px-4 py-0.5 border border-gray-300 rounded-full bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 whitespace-nowrap">
              Purchase Orders
            </button>
            <button className="px-4 py-0.5 border border-gray-300 rounded-full bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 whitespace-nowrap">
              Invoice Customs
            </button>
            <button className="px-4 py-0.5 border border-gray-300 rounded-full bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 whitespace-nowrap">
              PO Customs
            </button> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="md:min-w-[75vw] max-w-[90vw] bg-white shadow rounded-lg">
          <div className="flex space-x-4 p-4 justify-between overflow-x-auto no-scrollbar">
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Search documents"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="px-4 py-2 md:w-[18rem] border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
              />
              <select
                className="px-4 py-2 border md:w-[12rem] border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
                onChange={(e) => {
                  const selectedType = e.target.value;
                  handleTypeFilter(selectedType);
                }}
                value={typeFilter}
              >
                <option value="all">Type: All</option>
                <option value="folder">Folder</option>
                <option value="file">File</option>
              </select>

              <select
                className="px-4 py-2 border md:w-[12rem] border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
                onChange={(e) => {
                  const selectedStatus = e.target.value;
                  handleStatusFilter(selectedStatus);
                }}
                value={statusFilter}
              >
                <option value="all">Status: All</option>
                <option value="extracted">Extracted</option>
                <option value="not-extracted">Not Extracted</option>
              </select>
              <select
                className="px-4 py-2 border md:w-[12rem] border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
                onChange={(e) => {
                  const selectedFileType = e.target.value;
                  handleFileTypeFilter(selectedFileType);
                }}
                value={fileTypeFilter}
              >
                <option value="all">File Type: All</option>
                <option value="invoice">Invoice</option>
                <option value="receipt">Receipt</option>
              </select>
            </div>
            {/* <button className="bg-purple-600 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 hover:bg-purple-700">
              Search
            </button> */}
            <button
              className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              onClick={() => {
                handleClearFilter();
              }}
            >
              Clear Filters
            </button>
          </div>
          <div className="flex justify-center p-4">
            <Dropzone />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="md:min-w-[78vw] max-w-[90vw]">
          <div className="flex justify-between items-center p-4 overflow-x-auto no-scrollbar">
            <div className="flex items-center space-x-2">
              <label className="flex items-center space-x-1 whitespace-nowrap">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  value={selectedAllDocuments}
                  onChange={() =>
                    setSelectedAllDocuments(!selectedAllDocuments)
                  }
                />
                <span className="text-black">All Documents</span>
              </label>
              <button
                className="px-3 py-1 border whitespace-nowrap border-gray-300 rounded-md bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-400"
                onClick={handleBulkReview}
              >
                Bulk Review
              </button>
              <button
                className="px-3 py-1 border whitespace-nowrap border-gray-300 rounded-md bg-gray-200 font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-400"
                onClick={handleBulkDownload}
              >
                Bulk Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-10">
        <div className="md:min-w-[78vw] max-w-[90vw]">
          {/* Pass the new state and set function to DataTable */}
          <DataTable
            selectedAllDocuments={selectedAllDocuments}
            setBulkReviewContent={setBulkReviewContent}
            setBulkDownloadContent={setBulkDownloadContent}
          />
        </div>
      </div>
      <NewFolderModal
        isNewFolderModalOpen={isNewFolderModalOpen}
        closeNewFolderModal={() => setIsNewFolderModalOpen(false)}
      />
      {/* BulkPreviewModal component */}
      {isBulkPreviewModalOpen && (
        <BulkPreviewModal
          bulkPreviewModalContent={JSON.parse(
            localStorage.getItem("bulkReviewContent")
          )}
          isBulkPreviewModalOpen={isBulkPreviewModalOpen}
          closeBulkPreviewModal={closeBulkPreviewModal}
        />
      )}
      {isBulkDownloadModalOpen && (
        <BulkDownloadModal
          downloadBulkModalContent={bulkDownloadContent}
          isBulkDownloadModalOpen={isBulkDownloadModalOpen}
          closeBulkDownloadModal={() => setIsBulkDownloadModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Dashboard;
