import DateFormat from "./DateFormat";
import TimestampDateFormat from './DateFromTimestamp';
import moment from 'moment-timezone';
moment.tz.setDefault("Asia/Kolkata");

/*
| Date formatter
*/
export const formatDate = function (row, dateVal, dateFormat) {

    if (dateVal && dateVal !== '-') {
        return moment(dateVal).format(dateFormat)
        // return (<DateFormat date={dateVal} format={dateFormat} />);
    }
    return "-";
};

export const formatDateComponent = function (fieldName, row, format, customToolTip, hoverFormat) {
    return (
        <>
            <DateFormat fieldName={fieldName} format={format} row={row} customToolTip={customToolTip} hoverFormat={hoverFormat} />
        </>
    );
};

export const formatTimestampDateComponent = function (fieldName, row, format, customToolTip, hoverFormat) {
    return (
        <>
            <TimestampDateFormat fieldName={fieldName} format={format} row={row} customToolTip={customToolTip} hoverFormat={hoverFormat} />
        </>
    );
};