import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [tableLoader, setTableLoader] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [typeFilter, setTypeFilter] = useState("All");
  const [fileTypeFilter, setFileTypeFilter] = useState("All");
  const [documentType, setDocumentType] = useState("All");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const authData = localStorage.getItem("auth");
    const subscription = localStorage.getItem("subscription");
    if (authData) {
      setIsLoggedIn(true);
      userData();
    } else {
      setIsLoggedIn(false);
    }
    if (subscription === "true") {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }

    setLoading(false);
  }, []);

  const login = (authData) => {
    localStorage.setItem("auth", authData);
    setIsLoggedIn(true);
    fetchData();
    userData();
  };

  const logout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("folders");
    localStorage.removeItem("userData");
    localStorage.removeItem("subscription");
    setIsLoggedIn(false);
    setTableData([]);
    setAllTableData([]);
  };

  const userData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/user-details/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        const newUserData = response.data.data;

        const existingUserData =
          JSON.parse(localStorage.getItem("userData")) || {};

        const updatedUserData = { ...existingUserData, ...newUserData };

        localStorage.setItem("userData", JSON.stringify(updatedUserData));

        const { trial_expired_at, razorpay_subscription_end_at } = newUserData;

        const trialExpiredDate = new Date(trial_expired_at * 1000);
        const currentDate = new Date();

        let subscriptionEndDate;
        if (razorpay_subscription_end_at) {
          subscriptionEndDate = new Date(razorpay_subscription_end_at * 1000);
        }

        const isTrialExpired = trialExpiredDate < currentDate;
        const isSubscriptionExpired =
          subscriptionEndDate && subscriptionEndDate < currentDate;

        if (isTrialExpired && (!subscriptionEndDate || isSubscriptionExpired)) {
          setIsSubscribed(false);
          localStorage.setItem("subscription", "false");
          navigate("/plans");
        } else {
          // console.log("Subscription or trial is still active.");
          setIsSubscribed(true);
          localStorage.setItem("subscription", "true");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      const userId = JSON.parse(localStorage.getItem("auth"))?.user?.id;

      if (!token || !userId) {
        console.error("Access token or user ID not found in localStorage.");
        return;
      }
      setTableLoader(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/contents/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        const { folders, files } = response.data;

        // Create a map for easy access to folders and subfolders
        const folderMap = {};

        // List to store folder objects for local storage
        const folderListForStorage = [];

        // Process folders and subfolders
        const processFolders = (folderList) => {
          folderList.forEach((folder) => {
            if (folder.owner === userId) {
              folderMap[folder.id] = {
                ...folder,
                documents: [
                  ...(folder.files || []).filter(
                    (file) => file.owner === userId
                  ),
                ],
                subfolders: (folder.subfolders || []).filter(
                  (subfolder) => subfolder.owner === userId
                ),
              };

              folderListForStorage.push({ id: folder.id, name: folder.name });

              processFolders(folderMap[folder.id].subfolders);
            }
          });
        };

        processFolders(folders);

        const singleFiles = files.filter(
          (file) => file.folder === null && file.owner === userId
        );

        const combinedData = Object.values(folderMap);
        combinedData.push(...singleFiles);

        setGroupedData(folderMap);
        setTableData(combinedData);
        setAllTableData(combinedData);
        setTableLoader(false);

        localStorage.setItem("folders", JSON.stringify(folderListForStorage));
      } else {
        console.error("No data found in response");
        setTableLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setTableLoader(false);

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please log in again.");
        logout();
        window.location.href = "/login";
      }
    }
  };

  useEffect(() => {
    let filteredData = [...allTableData];

    if (searchKeyword) {
      filteredData = filteredData.filter((item) => {
        if (
          item.name &&
          item.name.toLowerCase().includes(searchKeyword.toLowerCase())
        ) {
          return true;
        }

        // Check if it's a file within a folder
        if (item.documents) {
          const matchedFiles = item.documents.filter((file) =>
            file.file
              .split("/")
              .pop()
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())
          );
          if (matchedFiles.length > 0) {
            return true;
          }
        }

        // Check if it's a single file (not in a folder)
        if (
          item.file_type &&
          item.file
            .split("/")
            .pop()
            .toLowerCase()
            .includes(searchKeyword.toLowerCase())
        ) {
          return true;
        }

        return false;
      });
    }

    if (typeFilter !== "All") {
      filteredData = filteredData.filter((item) => {
        if (typeFilter === "folder") {
          return item.documents;
        } else if (typeFilter === "file") {
          return !item.documents;
        }
        return true;
      });
    }

    if (statusFilter !== "All" && typeFilter !== "folder") {
      filteredData = filteredData.filter((item) => {
        if (statusFilter === "extracted") {
          return item.status === "EXTRACTED";
        } else if (statusFilter === "not-extracted") {
          return item.status === "NOT_EXTRACTED";
        }
        return true;
      });
    }
    if (fileTypeFilter !== "All" && typeFilter !== "folder") {
      filteredData = filteredData.filter((item) => {
        if (fileTypeFilter === "invoice") {
          return item.file_type === "INVOICE";
        } else if (fileTypeFilter === "receipt") {
          return item.file_type === "RECEIPT";
        }
        return true;
      });
    }

    setTableData(filteredData);
  }, [searchKeyword, statusFilter, typeFilter, allTableData, fileTypeFilter]);

  const applyFilter = (documentType) => {
    let filteredData = [...allTableData];
    if (documentType === "INVOICE") {
      filteredData = filteredData.filter(
        (item) => item.file_type === "INVOICE"
      );
    } else if (documentType === "RECEIPT") {
      filteredData = filteredData.filter(
        (item) => item.file_type === "RECEIPT"
      );
    }
    setTableData(filteredData);
  };

  useEffect(() => {
    applyFilter(documentType);
  }, [documentType]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        fetchData,
        tableData,
        setTableData,
        groupedData,
        tableLoader,
        applyFilter,
        searchKeyword,
        setSearchKeyword,
        setStatusFilter,
        statusFilter,
        setTypeFilter,
        typeFilter,
        documentType,
        setDocumentType,
        setFileTypeFilter,
        fileTypeFilter,
        userData,
        isSubscribed,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
