import React, { useState } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";

const Notifications = ({ isOpen, closeModal, notifications }) => {
  const [currentNotifications, setCurrentNotifications] =
    useState(notifications);

  // Handle individual notification deletion
  const handleDeleteNotification = (index) => {
    const updatedNotifications = currentNotifications.filter(
      (_, i) => i !== index
    );
    setCurrentNotifications(updatedNotifications);
  };

  // Handle deleting all notifications
  const handleDeleteAll = () => {
    setCurrentNotifications([]);
  };

  // Styles for modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "white",
      position: "relative",
      overflowY: "auto",
      maxHeight: "80vh",
      width: "80vw",
      maxWidth: "80vw",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Notifications Modal"
      className="notification-modal"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Notifications</h2>
        <button
          onClick={closeModal}
          className="text-red-500 hover:text-red-700"
        >
          <RxCross2 size={24} />
        </button>
      </div>
      <div className="space-y-4">
        {currentNotifications.length > 0 ? (
          currentNotifications.map((notification, index) => (
            <div
              key={index}
              className="bg-[#c2c5c1] border border-gray-300 rounded-full p-3 flex justify-between items-center shadow-sm"
            >
              <p className="text-gray-700">{notification}</p>
              <button
                onClick={() => handleDeleteNotification(index)}
                className="text-red-500 hover:text-red-700"
              >
                <RxCross2 size={20} />
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">No notifications</p>
        )}
      </div>
      {currentNotifications.length > 0 && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={handleDeleteAll}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Delete All
          </button>
        </div>
      )}
    </Modal>
  );
};

export default Notifications;
