import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import layoutLogo from "../assets/docuclipper-invoice-ocr-software-highest-accuracy-removebg-preview (1).png";
import logo from "../assets/2.png";
import Spinner from "../components/Spinner";
import axios from "axios";
import toast from "react-hot-toast";

const CompanyInfo = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    website: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
  });

  const [emailVerification, setEmailVerification] = useState(false);
  const [skipMessage, setSkipMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    if (authData && authData.user && authData.user.company !== null) {
      navigate("/");
    }
    if (authData && authData.user && authData.user.is_verified) {
      setEmailVerification(authData.user.is_verified);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        console.error("Access token not found in localStorage.");
        setLoading(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/user/update/`,
        {
          company: {
            name: formData.companyName,
            website: formData.website,
            address: formData.streetAddress,
            city: formData.city,
            state: formData.state,
            country: formData.country,
          },
          is_first_login: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Company information added successfully!");

        const updatedCompanyData = {
          name: formData.companyName,
          website: formData.website,
          address: formData.streetAddress,
          city: formData.city,
          state: formData.state,
          country: formData.country,
        };

        const authData = JSON.parse(localStorage.getItem("auth"));
        if (authData && authData.user) {
          authData.user.company = updatedCompanyData;
          localStorage.setItem("auth", JSON.stringify(authData));
          console.log("Local storage updated with new company data.");
        }

        setLoading(false);
        navigate("/");
      } else {
        console.error("Failed to update company data");
        toast.error("Failed to update company information.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating company data:", error);
      toast.error("Error updating company information.");
      setLoading(false);
    }
  };

  const handleSkip = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        console.error("Access token not found in localStorage.");
        setLoading(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/user/update/`,
        {
          is_first_login: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating company data:", error);
      toast.error("Error updating company information.");
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-slate-100 flex">
      <div className="hidden md:flex md:min-h-screen bg-[#383B43] w-full md:w-[50vw] justify-center items-center flex-col">
        <div className="fixed top-2 left-4 flex justify-center items-center ">
          <img src={logo} alt="Logo" className="h-20" />
        </div>
        <div>
          <img
            src={layoutLogo}
            alt="Layout Logo"
            className="md:h-[18rem] h-[10rem]"
          />
        </div>
      </div>
      <div className="min-h-screen bg-white w-full md:w-[50vw] flex flex-col justify-center items-center p-4 md:p-8">
        <div className="w-full md:w-[38vw] bg-white p-6 rounded-lg shadow">
          {loading ? (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex justify-between flex-wrap">
                <div className="flex justify-between items-center mb-6 border-2 border-black border-t-0 border-l-0 border-r-0">
                  <div className="flex items-center mb-2">
                    <h2 className="text-xl font-semibold mr-2">
                      Email Verification
                    </h2>
                    {emailVerification ? (
                      <FaCheckCircle className="text-green-500" size={24} />
                    ) : (
                      <FaTimesCircle className="text-red-500" size={24} />
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center mb-6 border-2 border-black border-t-0 border-l-0 border-r-0">
                  <div className="flex items-center mb-2">
                    <h2 className="text-xl font-semibold mr-2">
                      Company Information
                    </h2>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex justify-between flex-wrap">
                  <div className="flex flex-col">
                    <label htmlFor="companyName" className="mb-1">
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="website" className="mb-1">
                      Website
                    </label>
                    <input
                      type="text"
                      id="website"
                      name="website"
                      value={formData.website}
                      onChange={handleChange}
                      className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex justify-between flex-wrap">
                  <div className="flex flex-col">
                    <label htmlFor="streetAddress" className="mb-1">
                      Street Address
                    </label>
                    <input
                      type="text"
                      id="streetAddress"
                      name="streetAddress"
                      value={formData.streetAddress}
                      onChange={handleChange}
                      className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="city" className="mb-1">
                      City
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex justify-between flex-wrap">
                  <div className="flex flex-col">
                    <label htmlFor="state" className="mb-1">
                      State
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="country" className="mb-1">
                      Country
                    </label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex justify-start mt-4 space-x-4">
                  <button
                    type="submit"
                    className="mt-4 bg-[#383B43] text-white rounded-lg px-6 py-2 font-semibold shadow hover:bg-gray-200 hover:text-[#383B43] hover:font-bold transition duration-200"
                  >
                    Submit Company Information
                  </button>
                  <button
                    type="button"
                    onClick={handleSkip}
                    className="mt-4 bg-[#383B43] text-white rounded-lg px-6 py-2 font-semibold shadow hover:bg-gray-200 hover:text-[#383B43] hover:font-bold transition duration-200"
                  >
                    skip- I'm Individual
                  </button>
                </div>
              </form>
            </>
          )}
          {skipMessage && (
            <div className="mt-4 p-4 bg-yellow-100 text-yellow-800 rounded">
              {skipMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
