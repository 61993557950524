import React from "react";
import Modal from "react-modal";
import FileViewer from "./FileViewer";
import { RxCross2 } from "react-icons/rx";

const FilePreviewModal = ({
  isFilePreviewModalOpen,
  closeFilePreviewModal,
  previewModalContent,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      height: "90vh",
      maxWidth: "60vw",
      padding: "10px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      position: "relative",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  return (
    <Modal
      isOpen={isFilePreviewModalOpen}
      onRequestClose={closeFilePreviewModal}
      contentLabel="Preview Modal"
      style={customStyles}
    >
      <button
        onClick={closeFilePreviewModal}
        className="fixed top-1 right-2 text-gray-500 hover:text-red-700 focus:outline-none"
      >
        <RxCross2 size={24} />
      </button>
      {previewModalContent && (
        <div className="p-2 flex flex-col">
          <div className="p-2 flex flex-col md:flex-row md:gap-4 gap-20">
            <div className="basis-2/5 flex-grow md:min-h-[60vh] min-h-[20vh]  flex justify-center items-center">
              <FileViewer
                fileLink={previewModalContent.fileLink}
                fileType={previewModalContent.format}
                setResponseData={null}
                setIsLoading={null}
                previewModalContent={null}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FilePreviewModal;
