import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import FileViewer from "./FileViewer";
import VisualizedData from "./VisualizedData";
import Spinner from "./Spinner";
import ReactJson from "@vahagn13/react-json-view";
import { RxCross2 } from "react-icons/rx";

const PreviewModal = ({
  isPreviewModalOpen,
  closePreviewModal,
  previewModalContent,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      height: "88vh",
      maxWidth: "85vw",
      padding: "10px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      position: "relative",
      overflowY: "auto",
    },
    // content: {
    //   top: "50%",
    //   left: "50%",
    //   right: "auto",
    //   bottom: "auto",
    //   marginRight: "-50%",
    //   transform: "translate(-50%, -50%)",
    //   minWidth: "60vw",
    //   height: "85vh",
    //   maxWidth: "85vw",
    //   padding: "10px",
    //   borderRadius: "10px",
    //   backgroundColor: "#f3f4f6",
    //   position: "relative",
    //   overflowY: "scroll",
    //   maxHeight: "85vh",
    // },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    "@media (max-width: 768px)": {
      content: {
        minWidth: "85vw",
        height: "75vh",
        padding: "5px",
        overflowY: "auto",
      },
    },
    "@media (max-width: 480px)": {
      content: {
        minWidth: "95vw",
        height: "65vh",
        padding: "2px",
        overflowY: "auto",
      },
    },
  };

  const [responseData, setResponseData] = useState({});
  const [activeTab, setActiveTab] = useState("visualizedData");
  const [isLoading, setIsLoading] = useState(false);

  const jsonViewTheme = "dark";

  useEffect(() => {
    if (!isPreviewModalOpen) {
      setResponseData({});
    } else if (previewModalContent?.information) {
      setResponseData(previewModalContent.information);
    }
  }, [isPreviewModalOpen, previewModalContent]);

  // console.log(previewModalContent?.information);

  return (
    <Modal
      isOpen={isPreviewModalOpen}
      onRequestClose={closePreviewModal}
      contentLabel="Preview Modal"
      style={customStyles}
    >
      <button
        onClick={closePreviewModal}
        className="fixed top-1 right-2 text-gray-500 hover:text-red-700 focus:outline-none"
      >
        <RxCross2 size={24} />
      </button>
      {previewModalContent && (
        <div className="p-2 py-0.5 flex flex-col h-[80vh]">
          <div className="p-2 flex flex-col md:flex-row md:gap-4">
            <div className="basis-1/5 flex-grow md:min-h-[60vh] min-h-[50vh]  flex justify-center items-start">
              <FileViewer
                fileLink={previewModalContent.fileLink}
                fileType={previewModalContent.format}
                setResponseData={setResponseData}
                setIsLoading={setIsLoading}
                previewModalContent={previewModalContent}
              />
            </div>
            <div className="basis-4/5 flex-grow min-h-[35vh] p-1 md:mb-1 mb-12">
              <div
                className="flex justify-center items-center bg-[#ffffff] p-2 rounded-t-lg shadow-sm"
                style={{
                  borderWidth: "1px",
                }}
              >
                <div className="flex items-center">
                  <span className="text-black mr-2">
                    {previewModalContent?.name}
                  </span>
                </div>
              </div>
              <div>
                <div className="custom-pills flex gap-2 justify-around mt-1 flex-wrap">
                  <button
                    onClick={() => setActiveTab("visualizedData")}
                    className={` text-slate-800 py-2 px-4 rounded ${
                      activeTab === "visualizedData"
                        ? "border-b-slate-900 border-2 border-t-0 border-l-0 border-r-0"
                        : ""
                    }`}
                  >
                    Visualized Data
                  </button>
                  <button
                    onClick={() => setActiveTab("standardizedJSON")}
                    className={` text-slate-800 py-2 px-4 rounded ${
                      activeTab === "standardizedJSON"
                        ? "border-b-slate-900 border-2 border-t-0 border-l-0 border-r-0"
                        : ""
                    }`}
                  >
                    Standardized JSON
                  </button>
                </div>
                <div className="mt-1">
                  {activeTab === "visualizedData" && (
                    <>
                      {isLoading ? (
                        <div className="text-center mb-2">
                          <Spinner bdColor="border-slate-800" />
                        </div>
                      ) : (
                        <VisualizedData
                          responseData={responseData}
                          previewModalContent={previewModalContent}
                        />
                      )}
                    </>
                  )}
                  {activeTab === "standardizedJSON" && (
                    <>
                      {isLoading ? (
                        <div className="text-center mb-2">
                          <Spinner bdColor="border-slate-800" />
                        </div>
                      ) : (
                        <ReactJson
                          theme={jsonViewTheme}
                          src={responseData}
                          style={{
                            borderRadius: "0.3rem",
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px",
                            padding: "1rem",
                            backgroundColor: "#FFFFFF",
                            maxHeight: "30rem",
                            overflow: "auto",
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PreviewModal;
