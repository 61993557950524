import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { formatDateComponent } from "../components/Helper";
import PreviewModal from "../components/PreviewModal";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";

const Activities = () => {
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Handle row click
  const handleRowClick = (activity) => {
    const fileExtension = activity?.file?.file?.split(".").pop().toLowerCase();
    const fileType = ["pdf", "jpg", "jpeg", "png"].includes(fileExtension)
      ? fileExtension
      : "unknown";
    setSelectedActivity({
      fileLink:
        process.env.REACT_APP_BACKEND_URL + "/media/" + activity?.file?.file,
      format: fileType,
      name: activity?.file?.file?.split("/").pop(),
      id: activity?.file?.id,
      information: activity?.file?.information,
      status: activity?.file?.status,
    });
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedActivity(null);
  };

  // Fetch activity data
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("auth"))?.access;
        const userId = JSON.parse(localStorage.getItem("auth"))?.user?.id;

        if (!token || !userId) {
          console.error("Access token or user ID not found in localStorage.");
          navigate("/login"); // Redirect to login if no auth
          return;
        }

        setTableLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/activities/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTableData(response.data.activities);
      } catch (error) {
        setError("Error fetching data. Please try again later.");
        console.error("Error fetching table data:", error);
      } finally {
        setTableLoading(false);
      }
    };

    fetchTableData();
  }, [navigate]);

  // Format name or email
  const formatNameOrEmail = (updatedBy) => {
    const { first_name, last_name, email } = updatedBy;
    return first_name && last_name ? `${first_name} ${last_name}` : email;
  };

  return (
    <div>
      <Header classname={`${isModalOpen ? "" : "z-10"}`} />
      <div className="mt-10 bg-gray-50 shadow-lg rounded-lg my-6 w-full max-w-[90vw] lg:max-w-[80vw] mx-auto overflow-x-auto">
        <h2 className="text-3xl font-semibold mb-6 ml-4 text-gray-800">
          Activity Logs
        </h2>

        {tableLoading ? (
          <div className="flex justify-center items-center py-6">
            <BeatLoader color="#1D4ED8" />
          </div>
        ) : error ? (
          <div className="text-center text-red-600 font-medium py-4">
            {error}
          </div>
        ) : (
          <table
            className="min-w-full bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
            aria-label="Activity Logs Table"
            role="table"
          >
            <thead>
              <tr className="bg-blue-400 text-white text-sm uppercase tracking-wide font-bold">
                <th className="p-4 text-left">Activity Name</th>
                <th className="p-4 text-left">Uploaded By</th>
                <th className="p-4 text-left">Credits Charged</th>
                <th className="p-4 text-left">Charged At</th>
                <th className="p-4 text-left">Total Pages</th>
                <th className="p-4 text-left">Total Retry</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((activity, index) => (
                <tr
                  key={index}
                  className="bg-white hover:bg-gray-100 transition-colors duration-200 ease-in-out cursor-pointer border-b border-gray-200"
                  onClick={() => handleRowClick(activity)}
                >
                  <td className="p-4 text-gray-700 font-medium">
                    {activity.activity_name}
                  </td>
                  <td className="p-4 text-gray-600">
                    {formatNameOrEmail(activity.updated_by)}
                  </td>
                  <td className="p-4 text-gray-600">
                    {activity.charged_credits === null
                      ? "N/A"
                      : activity.charged_credits}
                  </td>
                  <td className="p-4 text-gray-600">
                    {formatDateComponent(
                      "updated_at",
                      activity,
                      "MMM D, YY h:mm a"
                    )}
                  </td>
                  <td className="p-4 text-gray-600">
                    {activity.file?.total_pages || "N/A"}
                  </td>
                  <td className="p-4 text-gray-600">
                    {activity.file?.total_retry || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Preview Modal */}
      {isModalOpen && (
        <PreviewModal
          isPreviewModalOpen={isModalOpen}
          closePreviewModal={closeModal}
          previewModalContent={selectedActivity}
        />
      )}
    </div>
  );
};

export default Activities;
