import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import PreviewModal from "./PreviewModal";
import NewFolderModal from "./NewFolderModal";
import { IoMdAdd } from "react-icons/io";
import toast from "react-hot-toast";
import { AuthContext } from "../AuthContext";
import axios from "axios";
import FilePreviewModal from "./FilePreviewModal";
import { RxCross2 } from "react-icons/rx";

const UploadModal = ({ isUploadModalOpen, closeUploadModal, files }) => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const { fetchData } = useContext(AuthContext);
  const [globalDocumentType, setGlobalDocumentType] = useState(
    "Global Document type"
  );
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalContent, setPreviewModalContent] = useState(null);
  const [folders, setFolders] = useState([]);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("Select Folder");

  // Load folders from localStorage
  useEffect(() => {
    const storedFolders = JSON.parse(localStorage.getItem("folders")) || [];
    setFolders(storedFolders);
  }, [fetchData]);

  // Handle global document type change
  useEffect(() => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = { ...prevSelectedFiles };
      Object.keys(updatedFiles).forEach((key) => {
        if (updatedFiles[key].selected) {
          updatedFiles[key] = {
            ...updatedFiles[key],
            type: globalDocumentType,
          };
        }
      });
      return updatedFiles;
    });
  }, [globalDocumentType]);

  // Handle preview modal open
  const handlePreviewClick = (file) => {
    setPreviewModalContent({
      fileLink: URL.createObjectURL(file),
      format: file.type.split("/")[1],
      name: file.name,
    });
    setIsPreviewModalOpen(true);
  };

  // Close preview modal
  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
    setPreviewModalContent(null);
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    const newSelectedFiles = {};
    files.forEach((file, index) => {
      newSelectedFiles[index] = {
        selected: checked,
        type: checked ? globalDocumentType : "Document type",
      };
    });
    setSelectedFiles(newSelectedFiles);
  };

  const handleFileSelect = (index) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [index]: {
        ...prevSelectedFiles[index],
        selected: !prevSelectedFiles[index]?.selected,
      },
    }));
  };

  const handleGlobalDocumentTypeChange = (event) => {
    setGlobalDocumentType(event.target.value);
  };

  const openNewFolderModal = () => {
    setIsNewFolderModalOpen(true);
  };

  const closeNewFolderModal = () => {
    setIsNewFolderModalOpen(false);
  };

  const handleFolderChange = (event) => {
    setSelectedFolder(event.target.value);
  };

  // const handleUpload = async () => {
  //   setIsLoading(true);
  //   const token = JSON.parse(localStorage.getItem("auth"))?.access;

  //   // Gather selected files
  //   const selectedFileEntries = Object.entries(selectedFiles).filter(
  //     ([, fileDetails]) => fileDetails.selected
  //   );

  //   if (selectedFileEntries.length === 0) {
  //     toast.error("No files selected for upload");
  //     setIsLoading(false);
  //     return;
  //   }

  //   // Get all unique document types of the selected files
  //   const uniqueDocumentTypes = [
  //     ...new Set(
  //       selectedFileEntries.map(([, fileDetails]) => fileDetails.type)
  //     ),
  //   ];

  //   try {
  //     if (uniqueDocumentTypes.length === 1) {
  //       // All selected files have the same document type
  //       const formData = new FormData();
  //       selectedFileEntries.forEach(([index]) => {
  //         formData.append("file", files[index]);
  //         formData.append("file_type", uniqueDocumentTypes[0].toUpperCase());
  //       });
  //       formData.append(
  //         "folder",
  //         selectedFolder !== "Select Folder" ? selectedFolder : ""
  //       );

  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/uploads/files/upload/`,
  //         formData,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       if (response?.data) {
  //         toast.success("Upload successful");
  //         fetchData();
  //         setSelectedFiles({});
  //       } else {
  //         toast.error("Upload failed");
  //       }
  //     } else {
  //       // Upload each file individually
  //       for (const [index, fileDetails] of selectedFileEntries) {
  //         const formData = new FormData();
  //         formData.append("file", files[index]);
  //         formData.append("file_type", fileDetails.type.toUpperCase());
  //         formData.append(
  //           "folder",
  //           selectedFolder !== "Select Folder" ? selectedFolder : ""
  //         );
  //         const response = await axios.post(
  //           `${process.env.REACT_APP_BACKEND_URL}/uploads/files/upload/`,
  //           formData,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         );

  //         if (response?.data) {
  //           toast.success(`File ${files[index].name} uploaded successfully`);
  //           fetchData();
  //         } else {
  //           toast.error(`Failed to upload ${files[index].name}`);
  //         }
  //       }
  //       setSelectedFiles({});
  //     }
  //   } catch (error) {
  //     console.error("Error uploading files:", error);
  //     toast.error("Error uploading files");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleUpload = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("auth"))?.access;
    const ownerId = JSON.parse(localStorage.getItem("auth"))?.user?.id;

    // Gather selected files
    const selectedFileEntries = Object.entries(selectedFiles).filter(
      ([, fileDetails]) => fileDetails.selected
    );

    if (selectedFileEntries.length === 0) {
      toast.error("No files selected for upload");
      setIsLoading(false);
      return;
    }

    try {
      for (const [index, fileDetails] of selectedFileEntries) {
        const fileType =
          fileDetails.type === "Document type" ||
          fileDetails.type === "Global Document type"
            ? "INVOICE"
            : fileDetails?.type?.toUpperCase();

        const formData = new FormData();
        formData.append("file", files[index]);
        formData.append("file_type", fileType ? fileType : "INVOICE");
        formData.append("owner", ownerId);
        formData.append(
          "folder",
          selectedFolder !== "Select Folder" ? selectedFolder : ""
        );

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/uploads/files/upload/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response?.data) {
          toast.success(`File ${files[index].name} uploaded successfully`);
          fetchData();
        } else {
          toast.error(`Failed to upload ${files[index].name}`);
        }
      }

      setSelectedFiles({});
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Error uploading files");
    } finally {
      setIsLoading(false);
    }
  };

  const areAllSelected =
    files.length > 0 &&
    Object.keys(selectedFiles).length === files.length &&
    Object.values(selectedFiles).every((file) => file.selected);

  // Styles for modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      height: "80vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      position: "relative",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <Modal
      isOpen={isUploadModalOpen}
      onRequestClose={closeUploadModal}
      contentLabel="Upload Modal"
      style={customStyles}
    >
      <button
        onClick={closeUploadModal}
        className="fixed top-1 right-2 text-gray-500 hover:text-red-700 focus:outline-none"
      >
        <RxCross2 size={24} />
      </button>
      <div className="p-4">
        <div className="mb-4 flex justify-between flex-wrap">
          <div>
            <input
              type="checkbox"
              id="selectAll"
              name="selectAll"
              onChange={handleSelectAll}
              checked={areAllSelected}
            />
            <label htmlFor="selectAll" className="ml-2">
              Select all documents
            </label>
          </div>

          <div className="flex items-center">
            <select
              className="border border-gray-400 px-2 py-1 rounded"
              value={selectedFolder}
              onChange={handleFolderChange}
            >
              <option value="">Select Folder</option>
              {folders.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))}
            </select>

            <button
              className="bg-blue-300 border border-gray-400 px-2 py-1 rounded ml-2"
              onClick={openNewFolderModal}
              title="Add New Folder"
            >
              <IoMdAdd size={20} />
            </button>
          </div>
          <div className="flex items-center">
            <select
              className="border border-gray-400 px-2 py-1 rounded"
              value={globalDocumentType}
              onChange={handleGlobalDocumentTypeChange}
            >
              <option value={"Global Document type"}>
                Global Document type
              </option>
              <option value={"INVOICE"}>Invoice</option>
              <option value={"RECEIPT"}>Receipt</option>
              {/* <option>Purchase_Order</option>
              <option>Invoice_Custom</option>
              <option>PO_Custom</option> */}
            </select>
          </div>
        </div>
        <div className="min-h-[50vh]">
          {files.map((file, index) => (
            <div key={index} className="flex items-center justify-between mb-4">
              <input
                type="checkbox"
                id={`file-${index}`}
                name={`file-${index}`}
                checked={selectedFiles[index]?.selected || false}
                onChange={() => handleFileSelect(index)}
              />
              <label htmlFor={`file-${index}`} className="ml-2 flex-grow">
                {file.name}
              </label>
              <button
                className="bg-gray-200 border border-gray-400 px-4 py-1 rounded"
                onClick={() => handlePreviewClick(file)}
              >
                Preview
              </button>
              <select
                className="border border-gray-400 px-2 py-1 rounded ml-2"
                value={selectedFiles[index]?.type || "Document type"}
                onChange={(e) => {
                  setSelectedFiles((prevSelectedFiles) => ({
                    ...prevSelectedFiles,
                    [index]: {
                      ...prevSelectedFiles[index],
                      type: e.target.value,
                    },
                  }));
                }}
              >
                <option value={"Document type"}>Document type</option>
                <option value={"INVOICE"}>Invoice</option>
                <option value={"RECEIPT"}>Receipt</option>
                {/* <option>Purchase_Order</option>
                <option>Invoice_Custom</option>
                <option>PO_Custom</option> */}
              </select>
            </div>
          ))}
        </div>
        <div className="flex justify-center space-x-5 mt-4">
          <button
            className="bg-gray-200 border border-gray-400 px-4 py-1 rounded"
            onClick={handleUpload}
            disabled={isLoading}
          >
            {isLoading ? "Uploading..." : "Upload"}
          </button>
          <button
            className="bg-gray-200 border border-gray-400 px-4 py-1 rounded"
            onClick={closeUploadModal}
          >
            Close
          </button>
        </div>
      </div>
      <FilePreviewModal
        isFilePreviewModalOpen={isPreviewModalOpen}
        closeFilePreviewModal={closePreviewModal}
        previewModalContent={previewModalContent}
      />
      <NewFolderModal
        isNewFolderModalOpen={isNewFolderModalOpen}
        closeNewFolderModal={closeNewFolderModal}
        parentFolderId={null}
      />
    </Modal>
  );
};

export default UploadModal;
