import React from "react";
import moment from "moment-timezone";
import { UncontrolledTooltip } from "reactstrap";

// Set the default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

const TimestampDateFormat = ({ fieldName, row, customToolTip = "" }) => {
  const rowId = `${fieldName}_${row.id}`;
  const value = row[fieldName];
  // console.log("value", value);

  // Set both formats to DD/MM/YYYY HH:mm
  const format = "YYYY-MM-DD 00:00";
  const hoverFormat = "DD/MM/YYYY HH:mm";

  // Ensure the value is a valid Unix timestamp and convert it to milliseconds
  const timestampInMillis = value ? parseInt(value) * 1000 : null;
  const formattedValue = timestampInMillis
    ? moment.tz(timestampInMillis, "Asia/Kolkata").format(format)
    : "-";
  const formattedHoverValue = timestampInMillis
    ? moment.tz(timestampInMillis, "Asia/Kolkata").format(hoverFormat)
    : "-";

  return (
    <>
      <span id={rowId}>{formattedValue}</span>
      {/* <UncontrolledTooltip delay={0} placement="auto" target={rowId}>
        {customToolTip ? customToolTip + " - " : ""} {formattedHoverValue}
      </UncontrolledTooltip> */}
    </>
  );
};

export default TimestampDateFormat;
