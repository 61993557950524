import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visiblePlans, setVisiblePlans] = useState([0, 4]);
  const [submittingPlanId, setSubmittingPlanId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/subscription/razorpay-plans/`
        );
        setPlans(response?.data?.data || []);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleNext = () => {
    setVisiblePlans([visiblePlans[0] + 1, visiblePlans[1] + 1]);
  };

  const handlePrev = () => {
    setVisiblePlans([visiblePlans[0] - 1, visiblePlans[1] - 1]);
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "INR":
        return "₹";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };

  const handleSubscribe = async (plan_id) => {
    try {
      setSubmittingPlanId(plan_id);
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        setSubmittingPlanId(null);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/razorpay-subscriptions/`,
        { plan_id: plan_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.data?.short_url) {
        navigate("/");
        window.open(response.data.data.short_url, "_blank");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log("Error subscribing to the plan:", error);
      toast.error("Error subscribing, please try again");
    } finally {
      setSubmittingPlanId(null);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <Header />

      <div className="text-center my-8">
        <h1 className="text-4xl font-bold">Plans</h1>
      </div>

      <div className="flex items-center justify-center gap-4">
        {visiblePlans[0] > 0 && (
          <button
            onClick={handlePrev}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8592;
          </button>
        )}

        <div className="flex flex-wrap flex-col items-center lg:flex-row lg:justify-center gap-8">
          {!isLoading &&
            !error &&
            plans.slice(visiblePlans[0], visiblePlans[1]).map((plan) => (
              <div
                key={plan.id}
                className="flex flex-col justify-between border border-black p-6 rounded-md min-h-72 w-72 text-center hover:shadow-lg"
              >
                <h2 className="text-5xl font-bold mb-4">
                  {getCurrencySymbol(plan.item.currency)}
                  {plan.item.amount / 100}
                </h2>
                <p className="text-gray-600 mb-4">
                  Billed {plan.interval} {plan.period}
                </p>
                <p className="text-lg mb-2 font-semibold">{plan.item.name}</p>
                <p className="text-lg mb-2">
                  {plan.item.description || "No description available"}
                </p>
                <button
                  className="bg-sky-400 text-white px-6 py-2 rounded-md hover:bg-sky-500"
                  onClick={() => handleSubscribe(plan.id)}
                  disabled={submittingPlanId === plan.id}
                >
                  {submittingPlanId === plan.id
                    ? "Redirecting..."
                    : "Subscribe Now"}
                </button>
              </div>
            ))}

          {isLoading && (
            <p>
              <BeatLoader />
            </p>
          )}
          {error && <p>Error loading plans: {error.message}</p>}
        </div>

        {visiblePlans[1] < plans.length && (
          <button
            onClick={handleNext}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8594;
          </button>
        )}
      </div>

      {/* Footer section */}
      <div className="flex flex-col md:flex-row md:justify-evenly items-center mt-8 mb-5">
        <p className="text-lg font-semibold mb-4">Need a Custom Plan?</p>
        <button className="border border-black px-6 py-2 rounded-md hover:bg-gray-200">
          Get a Quote
        </button>
      </div>
    </div>
  );
};

export default Plans;
